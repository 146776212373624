import { action, computed, makeObservable, observable } from 'mobx';

import DomainsService from "../data-services/DomainsService";
import { DomainDto, DomainsFilterDto, PostDomainDto } from '../dto/domain.dto';
import { PublisherDto, FilterPublisherDto } from "../dto/publisher.dto";
import { GAMInstanceDto } from "../dto/gamInstance.dto";
import { PartnerDto } from "../dto/partner.dto";
import PublisherService from "../data-services/PublisherService";
import PartnerService from "../data-services/PartnerService";
import GAMInstancesService from "../data-services/GAMInstancesService";
import { GAMIntegrationEventDto } from '../dto/gam_integration_event.dto';
import { AxiosError, AxiosResponse } from "axios";

export class DomainStore {
  @observable public domainsCount = 0;
  @observable public isLoading = false;
  @observable public isDomainsLoading = false;
  @observable public isFirstLoading = true;
  @observable public error = '';
  @observable private _domains: DomainDto[] = [];
  @observable private _gamInstances: GAMInstanceDto[] = [];
  @observable private _partners: PartnerDto[] = [];
  @observable private _publishers: PublisherDto[] = [];
  @observable private _integrationEvents: GAMIntegrationEventDto[] = [];

  constructor(
      private readonly DomainsApi: DomainsService,
      private readonly publisherApi: PublisherService,
      private readonly gamInstanceApi: GAMInstancesService,
      private readonly partnerApi: PartnerService,
  ) {
    makeObservable(this);
  }

  @action public clearError = async (): Promise<void> => {
    this.error = '';
  };

  @action public setError = (error: string): void => {
    this.error = error;
  };

  @action public setLoading = (state: boolean): void => {
    this.isLoading = state;
  };

  @action public setDomainLoading = (state: boolean): void => {
    this.isDomainsLoading = state;
  };

  @action public setDomainCount = (state: number): void => {
    this.domainsCount = state;
  };

  @action public setPublisher = (data: PublisherDto[]): void => {
    this._publishers = data;
  };

  @action public setPartners = (data: PartnerDto[]): void => {
    this._partners = data;
  };

  @action public setGamInstances = (data: GAMInstanceDto[]): void => {
    this._gamInstances = data;
  };

  @action public setDomains = (data: DomainDto[] | DomainDto, push = false): void => {
    if (push) {
      this._domains.push(data as DomainDto);
    } else {
      this._domains = data as DomainDto[];
    }
  };

  @action public pushDomains = async (
      domainIds: string[],
  ): Promise<string | null | undefined> => {
    try {
      this.setLoading(true);
      const { data: errors } = await this.DomainsApi.pushDomains(domainIds);
      await this.loadDomains();

      return errors;
    } catch (e) {
      this.setLoading(false);
      this.setError((e as Error).message);
      console.error(e);
    } finally {
      this.setLoading(false);
    }
  };

  @action public getPublishers = async (
      filter: FilterPublisherDto = {}
  ): Promise<PublisherDto[] | undefined> => {
    try {
      const { data } = await this.publisherApi.getPublishers(filter);

      if (data) {
        this.setPublisher(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    }
  };

  @action public getPartners = async (): Promise<PartnerDto[] | undefined> => {
    try {
      const { data } = await this.partnerApi.getPartners();

      if (data) {
        this.setPartners(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    }
  };

  @action public getGamInstance = async (): Promise<GAMInstanceDto[] | undefined> => {
    try {
      const { data } = await this.gamInstanceApi.getGAMInstance();

      if (data) {
        this.setGamInstances(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    }
  };

  @action public getLastIntegrationEvents = async (): Promise<GAMIntegrationEventDto[] | undefined> => {
    try {
      const { data } = await this.DomainsApi.getDomainIntegrationEvents();

      if (data) {
        this._integrationEvents = data;
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    }
  };

  @action public loadDomains = async (
      filter?: DomainsFilterDto,
  ): Promise<DomainDto[] | undefined> => {
    try {
      this.setLoading(true);
      this.setDomainLoading(true);

      const { data: domains } = await this.DomainsApi.getDomain(filter);

      this.setDomainCount(domains.count);
      this.setDomains(domains.rows);

      return domains.rows;
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setLoading(false);
      this.setDomainLoading(false);
    }
  };

  @action public createDomain = async (
      DomainData: PostDomainDto,
  ): Promise<void | { error: string }> => {
    try {
      this.setLoading(true);
      const { data } = await this.DomainsApi.createDomain(DomainData);

      if (data) {
        this.setDomains(data, true);
      }
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 409) {
        return { error: 'Such Domain already exists' };
      }
      this.setError((e as Error).message);
      console.error(e);
    } finally {
      this.setLoading(false);
    }
  };

  @action public updateDomain = async (
      id: string,
      DomainData: PostDomainDto,
  ): Promise<void | { error: string }> => {
    try {
      this.setLoading(true);
      await this.DomainsApi.updateDomain(id, DomainData);
      await this.loadDomains();
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 409) {
        return { error: 'Such Domain already exists' };
      }
      this.setError((e as Error).message);
      console.error(e);
    } finally {
      this.setLoading(false);
    }
  };

  @action public archiveDomain = async (DomainId: string | number): Promise<void> => {
    try {
      this.setLoading(true);
      await this.DomainsApi.archiveDomain(DomainId);

      await this.loadDomains();
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.setLoading(false);
    }
  };

  @action public importDomain = async (pushData: {
    files: File[];
    options: {
      gamInstanceId: string;
      publisherId: string;
      partnerId: string;
    }[];
  }): Promise<AxiosResponse<string | void>> => {
    try {
      this.setLoading(true);
      const data = await this.DomainsApi.importDomain(pushData);
      await this.loadDomains();

      return data;
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
      throw e;
    } finally {
      this.setLoading(false);
    }
  };

  @action public exportDomains = async (
     filter?: DomainsFilterDto
  ): Promise<AxiosResponse<BlobPart>> => this.DomainsApi.exportDomains(filter);

  @action public recoverDomain = async (DomainId: string | number): Promise<void> => {
    try {
      this.setLoading(true);
      await this.DomainsApi.recoverDomain(DomainId);

      await this.loadDomains();
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
      throw e;
    } finally {
      this.setLoading(false);
    }
  };

  @action public deleteDomain = async (DomainId: string | number): Promise<void> => {
    try {
      this.setLoading(true);
      await this.DomainsApi.deleteDomain(DomainId);

      this.setDomains(this._domains.filter(({ id }) => id !== DomainId));
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
      throw e;
    } finally {
      this.setLoading(false);
    }
  };

  @action public unique = (arr: {id: string; name: string}[]): {id: string; name: string}[] => {
    const result = arr.filter((item: any, index: number) => {
      const _thing = JSON.stringify(item);

      return index === arr.findIndex((obj: any) => JSON.stringify(obj) === _thing);
    });

    return result;
  };

  @computed public get domains (): DomainDto[] {
    return this._domains;
  }

  @computed public get publishers (): PublisherDto[] {
    return this._publishers;
  }

  @computed public get integrationEvents (): GAMIntegrationEventDto[] {
    return this._integrationEvents;
  }

  @computed public get allPublishers (): {id: string; name: string}[] {
    return this._publishers.map(x => ({
      id: x.id,
      name: x.name
    }));
  }

  @computed public get allPartners (): {id: string; name: string}[] {
    return this._partners.map(x => ({
      id: x.id,
      name: x.name
    }));
  }

  @computed public get allGAMInstances (): {id: string; name: string}[] {
    return this._gamInstances.map(x => ({
      id: x.id,
      name: x.networkId
    }));
  }
}
