import { AxiosInstance, AxiosResponse } from 'axios';
import { LoginUserDto, UserDto, UserTokenDto } from '../models/dto/user.dto';

export class UsersService {
  public url = '/users';

  constructor(private readonly client: AxiosInstance) {}

  public async login(data: LoginUserDto): Promise<AxiosResponse<UserTokenDto>> {
    return await this.client.post(`${this.url}/login`, data);
  }

  public async restoreSession(): Promise<AxiosResponse<UserDto>> {
    return await this.client.get(`${this.url}`);
  }
}
