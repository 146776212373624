import { action, computed, makeObservable, observable } from 'mobx';
import { AxiosError, AxiosResponse } from "axios";
import {
    AdsRecordDto,
    AdsRecordFilterDto,
    CreateAdsRecordDto,
    ImportAdsRecordsDto,
    UpdateAdsRecordDto
} from "../dto/ads_record.dto";
import AdsRecordsService from "../data-services/AdsRecordsService";
import { AdsDemandPartnerDto } from "../dto/ads_demand_partner.dto";
import AdsDemandPartnersService from "../data-services/AdsDemandPartnersService";

export class AdsRecordStore {
    @observable public error = '';
    @observable public adsRecordsCount = 0;
    @observable private _adsRecords: AdsRecordDto[] = [];
    @observable private _adsDemandPartners: AdsDemandPartnerDto[] = [];

    constructor(
        private readonly adsRecordsApi: AdsRecordsService,
        private readonly adsDemandPartnersApi: AdsDemandPartnersService,
    ) {
        makeObservable(this);
    }

    @computed public get adsRecords(): AdsRecordDto[] {
        return this._adsRecords;
    }

    @computed public get adsDemandPartners(): AdsDemandPartnerDto[] {
        return this._adsDemandPartners;
    }

    @action public setError = (error: string): void => {
        this.error = error;
    };

    @action public clearError = async (): Promise<void> => {
        this.error = '';
    };

    @action public setAdsRecords = (data: AdsRecordDto[]): void => {
        this._adsRecords = data;
    };

    @action public setAdsDemandPartners = (data: AdsDemandPartnerDto[]): void => {
        this._adsDemandPartners = data;
    };

    @action public setAdsRecordsCount = (value: number): void => {
        this.adsRecordsCount = value;
    };

    @action public getAdsRecords = async (filter?: AdsRecordFilterDto): Promise<AdsRecordDto[] | undefined> => {
        try {
            const { data } = await this.adsRecordsApi.getAdsRecords(filter);

            this.setAdsRecords(data.rows);
            this.setAdsRecordsCount(data.count);

            return data.rows;
        } catch (e) {
            this.setError((e as Error).message);
        }
    };

    @action public createAdsRecord = async (data: CreateAdsRecordDto): Promise<void | { error: string }> => {
        try {
            await this.adsRecordsApi.createAdsRecord(data);
        } catch (e) {
            if ((e as AxiosError)?.response?.status === 409) {
                return { error: `Such Record already exists` };
            }

            this.setError((e as Error).message);
        }
    };

    @action public updateAdsRecord = async (data: UpdateAdsRecordDto): Promise<void | { error: string }> => {
        try {
            await this.adsRecordsApi.updateAdsRecord(data);
        } catch (e) {
            if ((e as AxiosError)?.response?.status === 409) {
                return { error: `Such Record already exists` };
            }

            this.setError((e as Error).message);
        }
    };

    @action public deleteAdsRecords = async (adsRecordIds: string[]): Promise<void> => {
        try {
            await this.adsRecordsApi.deleteAdsRecords(adsRecordIds);
        } catch (e) {
            this.setError((e as Error).message);
        }
    };

    @action public importAdsRecords = async (
        data: ImportAdsRecordsDto
    ): Promise<AxiosResponse<void>> => this.adsRecordsApi.importAdsRecords(data);

    @action public exportAdsRecords = async (
        filter?: AdsRecordFilterDto
    ): Promise<AxiosResponse<BlobPart>> => this.adsRecordsApi.exportAdsRecords(filter);

    @action public getAdsDemandPartners = async (): Promise<AdsDemandPartnerDto[] | undefined> => {
        try {
            const { data } = await this.adsDemandPartnersApi.getAdsDemandPartners({
                orderedBy: [{ field: "name", isReversed: false }],
                withPublishers: true,
            });

            this.setAdsDemandPartners(data.rows);

            return data.rows;
        } catch (e) {
            this.setError((e as Error).message);
        }
    };
}
