import { AxiosInstance, AxiosResponse } from 'axios';
import { ListWithTotalDto } from "../dto/ListWithTotal.dto";
import {
    AdsDemandPartnerDto,
    AdsDemandPartnerFilterDto,
    CreateAdsDemandPartnerDto,
    UpdateAdsDemandPartnerDto
} from "../dto/ads_demand_partner.dto";

class AdsDemandPartnersService {
    public url = '/ads-demand-partners';

    constructor(private readonly client: AxiosInstance) {}

    public async getAdsDemandPartners(filter?: AdsDemandPartnerFilterDto): Promise<AxiosResponse<ListWithTotalDto<AdsDemandPartnerDto>>> {
        return await this.client.post(this.url, filter);
    }

    public async createAdsDemandPartner(partner: CreateAdsDemandPartnerDto): Promise<AxiosResponse<AdsDemandPartnerDto>> {
        return await this.client.post(`${this.url}/create`, partner);
    }

    public async updateAdsDemandPartner(partner: UpdateAdsDemandPartnerDto): Promise<AxiosResponse<AdsDemandPartnerDto>> {
        return await this.client.put(`${this.url}/${partner.id}`, partner);
    }

    public async exportAdsDemandPartners(filter?: AdsDemandPartnerFilterDto): Promise<AxiosResponse<BlobPart>> {
        return await this.client.post(`${this.url}/export`, filter);
    }

    public async deleteAdsDemandPartners(ids: string[]): Promise<AxiosResponse<void>> {
        return await this.client.delete(`${this.url}`, { data: ids });
    }
}

export default AdsDemandPartnersService;
