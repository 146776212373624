import { action, computed, makeObservable, observable } from 'mobx';
import { GAMInstanceDto, GAMInstancesFilterDto, PostGAMInstanceDto } from "../dto/gamInstance.dto";
import GAMInstancesService from "../data-services/GAMInstancesService";
import { AxiosError } from 'axios';

export class GAMInstanceStore {
  @observable public allGamInstances: GAMInstanceDto[] = [];
  @observable public isLoading = false;
  @observable public isGAMLoading = false;
  @observable public isFirstLoading = true;
  @observable public error = '';
  @observable private _gamInstances: GAMInstanceDto[] = [];

  constructor(
      private readonly gamInstancesApi: GAMInstancesService,
  ) {
    makeObservable(this);
  }

  @action public setLoading = (state: boolean): void => {
    this.isLoading = state;
  };

  @action public setGAMLoading = (state: boolean): void => {
    this.isGAMLoading = state;
  };


  @action public setFirstLoading = (state: boolean): void => {
    this.isFirstLoading = state;
  };

  @action public clearError = async (): Promise<void> => {
    this.error = '';
  };

  @action public setError = (error: string): void => {
    this.error = error;
  };

  @action public setGamInstances = (data: GAMInstanceDto[] | GAMInstanceDto, push = false): void => {
    if (push) {
      this._gamInstances.push(data as GAMInstanceDto);
    } else {
      this._gamInstances = data as GAMInstanceDto[];
    }
  };

  @action public setAllGamInstances = (data: GAMInstanceDto[] | GAMInstanceDto, push = false): void => {
    if (push) {
      this.allGamInstances.push(data as GAMInstanceDto);
    } else {
      this.allGamInstances = data as GAMInstanceDto[];
    }
  };

  @action public loadGAMInstances = async (
      filter?: GAMInstancesFilterDto,
  ): Promise<GAMInstanceDto[] | undefined> => {
    try {
      this.setLoading(true);
      this.setGAMLoading(true);
      const { data } = await this.gamInstancesApi.getGAMInstance(filter);

      if (data) {
        if (this.isFirstLoading) {
          this.setAllGamInstances(data);
          this.setFirstLoading(false);
        }
        this.setGamInstances(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setLoading(false);
      this.setGAMLoading(false);
    }
  };

  @action public createGAMInstance = async (
      GAMInstanceData: PostGAMInstanceDto,
  ): Promise<void | { error: string }> => {
    try {
      this.setLoading(true);
      const { data } = await this.gamInstancesApi.createGAMInstance(GAMInstanceData);

      if (data) {
        this.setGamInstances(data, true);
        this.setAllGamInstances(data, true);
      }
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 409) {
        return { error: 'Such GAM Instance already exists' };
      }
      this.setError((e as Error).message);
      console.error(e);
    } finally {
      this.setLoading(false);
    }
  };

  @action public updateGAMInstance = async (
      id: string,
      GAMInstanceData: PostGAMInstanceDto,
  ): Promise<void | { error: string }> => {
    try {
      this.setLoading(true);
      await this.gamInstancesApi.updateGAMInstance(id, GAMInstanceData);
      await this.loadGAMInstances();
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 409) {
        return { error: 'Such GAM Instance already exists' };
      }
      this.setError((e as Error).message);
      console.error(e);
    } finally {
      this.setLoading(false);
    }
  };

  @action public deleteGAMInstance = async (GAMInstanceId: string | number): Promise<void> => {
    try {
      this.setLoading(true);
      await this.gamInstancesApi.deleteGAMInstance(GAMInstanceId);

      this.setGamInstances(this._gamInstances.filter(({ id }) => id !== GAMInstanceId));
      this.setAllGamInstances(this.allGamInstances.filter(({ id }) => id !== GAMInstanceId));
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
      throw e;
    } finally {
      this.setLoading(false);
    }
  };

  @computed public get gamInstances (): GAMInstanceDto[] {
    return this._gamInstances;
  }

  @computed public get allGAMInstancesNames (): string[] {
    return this.allGamInstances.map(x => x.name);
  }

  @computed public get allGAMInstancesIds (): string[] {
    return this.allGamInstances.map(x => x.networkId);
  }

  @computed public get allGAMInstancesAdxIds (): string[] {
    return this.allGamInstances.map(x => x.adxId);
  }
}
