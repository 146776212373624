import { UsersService } from './UsersService';
import httpClientFactory from './httpClientFactory';
import configService from '../config/configService';
import GAMInstancesService from "./GAMInstancesService";
import PartnerService from './PartnerService';
import PublisherService from './PublisherService';
import DomainsService from "./DomainsService";
import AdsDemandPartnersService from "./AdsDemandPartnersService";
import AdsRecordsService from "./AdsRecordsService";
import AdsPublishersService from "./AdsPublishersService";
import AdsDomainsService from "./AdsDomainsService";

export const httpClient = httpClientFactory(configService);

export const usersApi = new UsersService(httpClient);
export const gamInstancesApi = new GAMInstancesService(httpClient);
export const partnerApi = new PartnerService(httpClient);
export const publisherApi = new PublisherService(httpClient);
export const domainsApi = new DomainsService(httpClient);
export const adsDemandPartnersApi = new AdsDemandPartnersService(httpClient);
export const adsRecordsApi = new AdsRecordsService(httpClient);
export const adsPublishersApi = new AdsPublishersService(httpClient);
export const adsDomainsApi = new AdsDomainsService(httpClient);

