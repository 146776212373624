import { action, computed, makeObservable, observable } from 'mobx';
import AdsDemandPartnersService from "../data-services/AdsDemandPartnersService";
import { AdsDemandPartnerDto, AdsDemandPartnerFilterDto, UpdateAdsDemandPartnerDto, CreateAdsDemandPartnerDto } from "../dto/ads_demand_partner.dto";
import { AxiosError, AxiosResponse } from "axios";

export class AdsDemandPartnerStore {
    @observable public isLoading = false;
    @observable public error = '';
    @observable public adsDemandPartnersCount = 0;
    @observable private _adsDemandPartners: AdsDemandPartnerDto[] = [];

    constructor(
        private readonly adsDemandPartnersApi: AdsDemandPartnersService,
    ) {
        makeObservable(this);
    }

    @computed public get adsDemandPartners (): AdsDemandPartnerDto[] {
        return this._adsDemandPartners;
    }

    @action public setError = (error: string): void => {
        this.error = error;
    };

    @action public clearError = async (): Promise<void> => {
        this.error = '';
    };

    @action public setLoading = (state: boolean): void => {
        this.isLoading = state;
    };

    @action public setAdsDemandPartners = (data: AdsDemandPartnerDto[]): void => {
        this._adsDemandPartners = data;
    };

    @action public setAdsDemandPartnersCount = (value: number): void => {
        this.adsDemandPartnersCount = value;
    };

    @action public getAdsDemandPartners = async (filter: AdsDemandPartnerFilterDto): Promise<AdsDemandPartnerDto[] | undefined> => {
        try {
            this.setLoading(true);
            const { data } = await this.adsDemandPartnersApi.getAdsDemandPartners(filter);

            this.setAdsDemandPartners(data.rows);
            this.setAdsDemandPartnersCount(data.count);

            return data.rows;
        } catch (e) {
            this.setError((e as Error).message);
        } finally {
            this.setLoading(false);
        }
    };

    @action public createAdsDemandPartner = async (data: CreateAdsDemandPartnerDto): Promise<void | { error: string }> => {
        try {
            await this.adsDemandPartnersApi.createAdsDemandPartner(data);
        } catch (e) {
            if ((e as AxiosError)?.response?.status === 409) {
                return { error: 'Such Demand Partner already exists' };
            }

            this.setError((e as Error).message);
        }
    };

    @action public updateAdsDemandPartner = async (data: UpdateAdsDemandPartnerDto): Promise<void | { error: string }> => {
        try {
            await this.adsDemandPartnersApi.updateAdsDemandPartner(data);
        } catch (e) {
            if ((e as AxiosError)?.response?.status === 409) {
                return { error: 'Such Demand Partner already exists' };
            }

            this.setError((e as Error).message);
        }
    };

    @action public exportAdsDemandPartners = async (
        filter?: AdsDemandPartnerFilterDto
    ): Promise<AxiosResponse<BlobPart>> => this.adsDemandPartnersApi.exportAdsDemandPartners(filter);

    @action public deleteAdsDemandPartners = async (adsDemandPartnerIds: string[]): Promise<void> => {
        try {
            await this.adsDemandPartnersApi.deleteAdsDemandPartners(adsDemandPartnerIds);
        } catch (e) {
            this.setError((e as Error).message);
        }
    };
}
