import { action, makeObservable, observable } from 'mobx';

import { UsersService } from "../data-services/UsersService";
import { LoginUserDto, UserDto, UserTokenDto } from '../models/dto/user.dto';
import { ConfigService } from "../config/configService";
import { ConfigParam } from "../config/configParam.enum";

export class UserStore {
  @observable public user: UserDto | undefined;
  @observable public token = '';
  @observable public error = '';
  @observable public gamApiVersion = '';
  @observable public gamApiExpiryDate: Date | undefined;
  @observable public gamReminderModalIsOpen = false;
  public tokenKey = 'token';

  constructor(
      private readonly configService: ConfigService,
      private readonly usersApi: UsersService,
  ) {
    makeObservable(this);
    this.token = this.getToken() as string;
    this.gamApiVersion = this.configService.get<string>(ConfigParam.GAM_API_VERSION) ?? '';
    const gamApiExpiryDate = new Date(this.configService.get<string>(ConfigParam.GAM_API_EXPIRY_DATE) ?? '');

    if (!isNaN(gamApiExpiryDate.valueOf())) {
      this.gamApiExpiryDate = gamApiExpiryDate;
    }

    if (this.token) {
      this.restoreSession(this.token);
    }
  }

  @action public openGamReminderModal = (): void => {
    this.gamReminderModalIsOpen = true;
  };

  @action public closeGamReminderModal = (): void => {
    this.gamReminderModalIsOpen = false;
  };

  @action public setError = (error: string): void => {
    this.error = error;
  };

  @action public login = async (
    loginData: LoginUserDto,
  ): Promise<UserTokenDto | undefined> => {
    try {
      const { data } = await this.usersApi.login(loginData);

      if (!data) {
        return;
      }

      this.setToken(data);

      if (this.gamApiVersion && this.gamApiExpiryDate) {
        const gamReminderStartTimestamp = new Date(this.gamApiExpiryDate).setDate(this.gamApiExpiryDate.getDate() - 60) / 1000;
        const gamReminderEndTimestamp = new Date(this.gamApiExpiryDate).setDate(this.gamApiExpiryDate.getDate() - 1) / 1000;
        const currentTimestamp = Date.now() / 1000;

        if (gamReminderStartTimestamp <= currentTimestamp && currentTimestamp <= gamReminderEndTimestamp) {
          this.openGamReminderModal();
        }
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
    }
  };

  @action public logOut = async (): Promise<void> => {
    try {
      this.removeToken();
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
    }
  };

  @action public restoreSession = async (token: string): Promise<void> => {
    try {
      const { data } = await this.usersApi.restoreSession();

      if (data) {
        this.setUserData({
          user: data,
          token,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  @action public setUserData = (userData: UserTokenDto): void => {
    this.token = userData.token;
    this.user = userData.user;
  };

  @action public getToken = (): string | null =>
    localStorage.getItem(this.tokenKey);

  @action public setToken = (userData: UserTokenDto): void => {
    this.setUserData(userData);
    localStorage.setItem(this.tokenKey, userData.token);
  };

  @action public removeToken = (): void => {
    this.token = '';
    localStorage.clear();
  };

  @action public clearError = (): void => {
    this.error = '';
  };
}
