import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { ConfigParam } from '../config/configParam.enum';
import { ConfigService } from '../config/configService';
import { userStore } from "../stores";

const httpClientFactory = (configService: ConfigService): AxiosInstance => {
  const httpClient = axios.create({
    baseURL: configService.get<string>(ConfigParam.API_URL),
    timeout: 6000000,
  });

  const authHeaderInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const tokenFromLocalStorage = localStorage.getItem('token');

    if (tokenFromLocalStorage) {
      config.headers.Authorization = `Bearer ${tokenFromLocalStorage}`;
    }

    return config;
  };

  const interceptResponseError = (error: AxiosError): any => {
    if (error.response?.data?.statusCode === 401) {
      userStore.removeToken();
    }

    if (error.response?.data?.message) {
      error.message = error.response.data.message;
    }

    return Promise.reject(error);
  };

  httpClient.interceptors.request.use(authHeaderInterceptor);
  httpClient.interceptors.response.use(
      (data: AxiosResponse): AxiosResponse => data,
      interceptResponseError
  );

  return httpClient;
};

export default httpClientFactory;
