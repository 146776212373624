import { AxiosInstance, AxiosResponse } from 'axios';
import { ListWithTotalDto } from "../dto/ListWithTotal.dto";
import { AdsDomainDto, AdsDomainFilterDto, CreateAdsDomainDto, UpdateAdsDomainDto } from "../dto/ads_domain.dto";
import { AdsRecordScanningResultDto } from "../dto/ads_record_scanning_result.dto";

class AdsDomainsService {
    public url = '/ads-domains';

    constructor(private readonly client: AxiosInstance) {}

    public async getAdsDomains(filter?: AdsDomainFilterDto): Promise<AxiosResponse<ListWithTotalDto<AdsDomainDto>>> {
        return await this.client.post(this.url, filter);
    }

    public async getRecordScanningResults(id: string): Promise<AxiosResponse<AdsRecordScanningResultDto[]>> {
        return await this.client.get(`${this.url}/${id}/record-scanning-results`);
    }

    public async createAdsDomain(domain: CreateAdsDomainDto): Promise<AxiosResponse<AdsDomainDto>> {
        return await this.client.post(`${this.url}/create`, domain);
    }

    public async updateAdsDomain(domain: UpdateAdsDomainDto): Promise<AxiosResponse<AdsDomainDto>> {
        return await this.client.put(`${this.url}/${domain.id}`, domain);
    }

    public async exportAdsDomains(filter?: AdsDomainFilterDto): Promise<AxiosResponse<BlobPart>> {
        return await this.client.post(`${this.url}/export`, filter);
    }

    public async deleteAdsDomains(ids: string[]): Promise<AxiosResponse<void>> {
        return await this.client.delete(`${this.url}`, { data: ids });
    }
}

export default AdsDomainsService;
