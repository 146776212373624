import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const CenteredLoader: React.FC = () => (
  <Box
    display="flex"
    height="100vh"
    width="100%"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Box>
);

export default CenteredLoader;
