import { action, computed, makeObservable, observable } from 'mobx';
import { CreatePartnerDto, UpdatePartnerDto, PartnerFilterDto, PartnerDto } from '../dto/partner.dto';
import PartnerService from '../data-services/PartnerService';
import { PublisherDto, FilterPublisherDto } from '../dto/publisher.dto';
import PublisherService from '../data-services/PublisherService';

export class PartnerStore {
  @observable public isLoading = false;
  @observable public isPartnerLoading = false;
  @observable public error = '';
  @observable private _partners: PartnerDto[] = [];
  @observable private _publishers: PublisherDto[] = [];

  constructor(
      private readonly partnerApi: PartnerService,
      private readonly publisherApi: PublisherService,
  ) {
    makeObservable(this);
  }

  @computed public get partners (): PartnerDto[] {
    return this._partners;
  }

  @computed public get publishers (): PublisherDto[] {
    return this._publishers;
  }

  @action public clearError = async (): Promise<void> => {
    this.error = '';
  };

  @action public setLoading = (state: boolean): void => {
    this.isLoading = state;
  };

  @action public setPartnerLoading = (state: boolean): void => {
    this.isPartnerLoading = state;
  };

  @action public setError = (error: string): void => {
    this.error = error;
  };

  @action public setPublisher = (data: PublisherDto[]): void => {
    this._publishers = data;
  };

  @action public setPartners = (data: PartnerDto[]): void => {
    this._partners = data;
  };

  @action public getPartners = async (
      filter?: PartnerFilterDto,
  ): Promise<PartnerDto[] | undefined> => {
    try {
      this.setLoading(true);
      const { data } = await this.partnerApi.getPartners(filter);

      if (data) {
        this.setPartners(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
    } finally {
      this.setLoading(false);
    }
  };

  @action public getPublishers = async (
      filter: FilterPublisherDto = {}
  ): Promise<PublisherDto[] | undefined> => {
    try {
      this.setPartnerLoading(true);
      const { data } = await this.publisherApi.getPublishers(filter);

      if (data) {
        this.setPublisher(data);
      }

      return data;
    } catch (e) {
      this.setError((e as Error).message);
      this.setPartnerLoading(false);
    }
  };

  @action public createPartner = async (
      partnerData: CreatePartnerDto,
  ): Promise<void> => {
    try {
      await this.partnerApi.createPartner(partnerData);
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
    }
  };

  @action public updatePartner = async (
      partnerData: UpdatePartnerDto,
  ): Promise<void> => {
    try {
      await this.partnerApi.updatePartner(partnerData);
    } catch (e) {
      this.setError((e as Error).message);
      console.error(e);
    }
  };

  @action public deletePartner = async (partnerId: string): Promise<void> => {
    try {
      await this.partnerApi.deletePartner(partnerId);

      this.setPartners(this._partners.filter(({ id }) => id !== partnerId));
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
}
