import { action, computed, makeObservable, observable } from 'mobx';
import { AxiosError, AxiosResponse } from "axios";
import {
    AdsPublisherDto,
    AdsPublisherErrorDto,
    AdsPublisherFilterDto,
    CreateAdsPublisherDto,
    UpdateAdsPublisherDto
} from "../dto/ads_publisher.dto";
import AdsPublishersService from "../data-services/AdsPublishersService";
import { AdsDemandPartnerDto } from "../dto/ads_demand_partner.dto";
import AdsDemandPartnersService from "../data-services/AdsDemandPartnersService";
import { AdsRecordsScanningProcessDto } from "../dto/ads_records_scanning_process.dto";

export class AdsPublisherStore {
    @observable public error = '';
    @observable public adsPublishersCount = 0;
    @observable public isAdsPublisherLoading = false;
    @observable public latestScan: AdsRecordsScanningProcessDto | undefined;
    @observable private _adsPublishers: AdsPublisherDto[] = [];
    @observable private _adsDemandPartners: AdsDemandPartnerDto[] = [];

    constructor(
        private readonly adsPublishersApi: AdsPublishersService,
        private readonly adsDemandPartnersApi: AdsDemandPartnersService,
    ) {
        makeObservable(this);
    }

    @computed public get adsPublishers(): AdsPublisherDto[] {
        return this._adsPublishers;
    }

    @computed public get adsDemandPartners(): AdsDemandPartnerDto[] {
        return this._adsDemandPartners;
    }

    @action public setIsAdsPublisherLoading = (value: boolean): void => {
        this.isAdsPublisherLoading = value;
    };

    @action public setError = (error: string): void => {
        this.error = error;
    };

    @action public clearError = async (): Promise<void> => {
        this.error = '';
    };

    @action public setAdsPublishers = (data: AdsPublisherDto[]): void => {
        this._adsPublishers = data;
    };

    @action public setAdsPublishersCount = (value: number): void => {
        this.adsPublishersCount = value;
    };

    @action public setAdsDemandPartners = (data: AdsDemandPartnerDto[]): void => {
        this._adsDemandPartners = data;
    };

    @action public setLatestScan = (data: AdsRecordsScanningProcessDto): void => {
        this.latestScan = data;
    };

    @action public getAdsPublishers = async (filter?: AdsPublisherFilterDto): Promise<AdsPublisherDto[] | undefined> => {
        try {
            this.setIsAdsPublisherLoading(true);
            const { data } = await this.adsPublishersApi.getAdsPublishers({ ...filter, withDemandPartners: true });

            this.setAdsPublishers(data.rows);
            this.setAdsPublishersCount(data.count);

            return data.rows;
        } catch (e) {
            this.setError((e as Error).message);
        } finally {
            this.setIsAdsPublisherLoading(false);
        }
    };

    @action public createAdsPublisher = async (data: CreateAdsPublisherDto): Promise<void | { error: AdsPublisherErrorDto }> => {
        try {
            await this.adsPublishersApi.createAdsPublisher(data);
        } catch (e) {
            const { status, data } = (e as AxiosError).response ?? {};

            if (status === 409) {
                return { error: { message: 'Such Publisher already exists', field: data.exceptionDetails.field } };
            }

            this.setError((e as Error).message);
        }
    };

    @action public updateAdsPublisher = async (data: UpdateAdsPublisherDto): Promise<void | { error: AdsPublisherErrorDto }> => {
        try {
            await this.adsPublishersApi.updateAdsPublisher(data);
        } catch (e) {
            if ((e as AxiosError).response?.status === 409) {
                const { data } = (e as AxiosError).response ?? {};

                return { error: { message: 'Such Publisher already exists', field: data.exceptionDetails.field } };
            }

            this.setError((e as Error).message);
        }
    };

    @action public exportAdsPublishers = async (
        filter?: AdsPublisherFilterDto
    ): Promise<AxiosResponse<BlobPart>> => this.adsPublishersApi.exportAdsPublishers(filter);

    @action public deleteAdsPublishers = async (adsPublisherIds: string[]): Promise<void> => {
        try {
            await this.adsPublishersApi.deleteAdsPublishers(adsPublisherIds);
        } catch (e) {
            this.setError((e as Error).message);
        }
    };

    @action public getAdsDemandPartners = async (): Promise<AdsDemandPartnerDto[] | undefined> => {
        try {
            const { data } = await this.adsDemandPartnersApi.getAdsDemandPartners({
                orderedBy: [{ field: 'name', isReversed: false }],
                withRecords: true,
            });

            this.setAdsDemandPartners(data.rows);

            return data.rows;
        } catch (e) {
            this.setError((e as Error).message);
        }
    };

    @action public getLatestScan = async (): Promise<AdsRecordsScanningProcessDto | undefined> => {
        try {
            const { data } = await this.adsPublishersApi.getLatestScan();

            this.setLatestScan(data);

            return data;
        } catch (e) {
            this.setError((e as Error).message);
        }
    };
}
