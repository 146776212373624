import { action, computed, makeObservable, observable } from 'mobx';
import { AxiosError, AxiosResponse } from "axios";

import { AdsDomainDto, AdsDomainFilterDto, CreateAdsDomainDto, UpdateAdsDomainDto } from "../dto/ads_domain.dto";
import { AdsPublisherDto } from "../dto/ads_publisher.dto";
import AdsDomainsService from "../data-services/AdsDomainsService";
import AdsPublishersService from "../data-services/AdsPublishersService";

export class AdsDomainStore {
  @observable public error = '';
  @observable public adsDomainsCount = 0;
  @observable private _adsDomains: AdsDomainDto[] = [];
  @observable private _adsPublishers: AdsPublisherDto[] = [];

  constructor(
      private readonly adsDomainsApi: AdsDomainsService,
      private readonly adsPublishersService: AdsPublishersService,
  ) {
    makeObservable(this);
  }

  @computed public get adsDomains(): AdsDomainDto[] {
    return this._adsDomains;
  }

  @computed public get adsPublishers(): AdsPublisherDto[] {
    return this._adsPublishers;
  }

  @action public setError = (error: string): void => {
    this.error = error;
  };

  @action public clearError = async (): Promise<void> => {
    this.error = '';
  };

  @action public setAdsDomains = (data: AdsDomainDto[]): void => {
    this._adsDomains = data;
  };

  @action public setAdsPublishers = (data: AdsPublisherDto[]): void => {
    this._adsPublishers = data;
  };

  @action public setAdsDomainsCount = (value: number): void => {
    this.adsDomainsCount = value;
  };

  @action public getAdsDomains = async (filter?: AdsDomainFilterDto): Promise<AdsDomainDto[] | undefined> => {
    try {
      const { data } = await this.adsDomainsApi.getAdsDomains(filter);

      this.setAdsDomains(data.rows);
      this.setAdsDomainsCount(data.count);

      return data.rows;
    } catch (e) {
      this.setError((e as Error).message);
    }
  };

  @action public createAdsDomain = async (data: CreateAdsDomainDto): Promise<void | { error: string }> => {
    try {
      await this.adsDomainsApi.createAdsDomain(data);
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 409) {
        return { error: `Such Domain already exists` };
      }

      this.setError((e as Error).message);
    }
  };

  @action public updateAdsDomain = async (data: UpdateAdsDomainDto): Promise<void | { error: string }> => {
    try {
      await this.adsDomainsApi.updateAdsDomain(data);
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 409) {
        return { error: `Such Domain already exists` };
      }

      this.setError((e as Error).message);
    }
  };

  @action public deleteAdsDomains = async (adsDomainIds: string[]): Promise<void> => {
    try {
      await this.adsDomainsApi.deleteAdsDomains(adsDomainIds);
    } catch (e) {
      this.setError((e as Error).message);
    }
  };

  @action public exportAdsDomains = async (
      filter?: AdsDomainFilterDto
  ): Promise<AxiosResponse<BlobPart>> => this.adsDomainsApi.exportAdsDomains(filter);

  @action public getAdsPublishers = async (): Promise<AdsPublisherDto[] | undefined> => {
    try {
      const { data } = await this.adsPublishersService.getAdsPublishers({
        orderedBy: [{ field: 'name', isReversed: false }]
      });

      this.setAdsPublishers(data.rows);

      return data.rows;
    } catch (e) {
      this.setError((e as Error).message);
    }
  };
}
