import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import { userStore } from '../../stores';
import { RouteProps } from 'react-router';

const PrivateRoute: React.FC<RouteProps> = observer((props) => {
  const { token } = userStore;

  if (!token) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
});

export default PrivateRoute;
