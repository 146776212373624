import { AxiosInstance, AxiosResponse } from 'axios';
import {
  GAMInstanceDto,
  GAMInstancesFilterDto,
  PostGAMInstanceDto
} from "../dto/gamInstance.dto";

class GAMInstancesService {
  public url = '/gam';

  constructor(private readonly client: AxiosInstance) {}

  public async getGAMInstance(filter?: GAMInstancesFilterDto): Promise<AxiosResponse<GAMInstanceDto[]>> {
    return await this.client.get(this.url, { params: filter });
  }

  public async createGAMInstance(
      GAMInstanceData: PostGAMInstanceDto,
  ): Promise<AxiosResponse<GAMInstanceDto>> {
    return await this.client.post(this.url, GAMInstanceData);
  }

  public async updateGAMInstance(
      id: string,
      GAMInstanceData: PostGAMInstanceDto,
  ): Promise<AxiosResponse<GAMInstanceDto>> {
    return await this.client.put(`${this.url}/${id}`, GAMInstanceData);
  }

  public async deleteGAMInstance(id: string | number): Promise<AxiosResponse<void>> {
    return await this.client.delete(`${this.url}/${id}`);
  }

  public async isGAMCanBeDeleted(id: string): Promise<AxiosResponse<boolean>> {
    return await this.client.get(`${this.url}/canBeDeleted/${id}`);
  }
}

export default GAMInstancesService;
