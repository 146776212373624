import React, { FunctionComponent, Suspense } from 'react';
import { CssBaseline } from '@material-ui/core';
import { Switch } from "react-router-dom";

import AuthRoute from '../../components/AuthRoute';
import CenteredLoader from '../../components/UI/CenteredLoader';
import PrivateRoute from '../../components/PrivateRoute';

const LoginPageLazy = React.lazy(
    async () => import('../../pages/LoginPage/LoginPage'),
);

const AuthenticatedAreaLazy = React.lazy(
    async () => import('../../pages/AuthenticatedArea/AuthenticatedArea'),
);

export const Root: FunctionComponent = () => (
    <>
        <CssBaseline />
        <Suspense fallback={<CenteredLoader />}>
            <Switch>
                <AuthRoute exact path="/login" component={LoginPageLazy} />

                <PrivateRoute path="/" component={AuthenticatedAreaLazy} />
            </Switch>
        </Suspense>
    </>
);

export default Root;
