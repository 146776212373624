import { AxiosInstance, AxiosResponse } from 'axios';
import {
  DomainDto,
  DomainsFilterDto,
  PostDomainDto
} from "../dto/domain.dto";
import { GAMIntegrationEventDto } from '../dto/gam_integration_event.dto';
import { ListWithTotalDto } from "../dto/ListWithTotal.dto";

class DomainsService {
  public url = '/domains';

  constructor(private readonly client: AxiosInstance) {}

  public async getDomain(filter?: DomainsFilterDto): Promise<AxiosResponse<ListWithTotalDto<DomainDto>>> {
    return await this.client.post(this.url, filter);
  }

  public async createDomain(
      DomainData: PostDomainDto,
  ): Promise<AxiosResponse<DomainDto>> {
    return await this.client.post(`${this.url}/create`, DomainData);
  }

  public async updateDomain(
      id: string,
      DomainData: PostDomainDto,
  ): Promise<AxiosResponse<DomainDto>> {
    return await this.client.put(`${this.url}/${id}`, DomainData);
  }

  public async importDomain(pushData: {
    files: File[];
    options: {
      gamInstanceId: string;
      publisherId: string;
      partnerId: string;
    }[];
  }): Promise<AxiosResponse<string | void>> {
    const { files, options } = pushData;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      formData.append('options[]', JSON.stringify(options[i]));
    }

    return await this.client.post(`${this.url}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  public async exportDomains(filter?: DomainsFilterDto): Promise<AxiosResponse<BlobPart>> {
    return await this.client.post(`${this.url}/export`, filter);
  }

  public async recoverDomain(id: string | number): Promise<AxiosResponse<void>> {
    return await this.client.put(`${this.url}/recover/${id}`);
  }

  public async archiveDomain(id: string | number): Promise<AxiosResponse<void>> {
    return await this.client.put(`${this.url}/archive/${id}`);
  }

  public async deleteDomain(id: string | number): Promise<AxiosResponse<void>> {
    return await this.client.delete(`${this.url}/${id}`);
  }

  public async pushDomains(domainIds: string[]): Promise<AxiosResponse<string | null>> {
    return await this.client.post(`${this.url}/push-to-gam`, domainIds);
  }

  public async getDomainIntegrationEvents(): Promise<AxiosResponse<GAMIntegrationEventDto[]>> {
    return await this.client.get(`${this.url}/integration-events`);
  }
}

export default DomainsService;
