import { AxiosInstance, AxiosResponse } from 'axios';
import { ListWithTotalDto } from "../dto/ListWithTotal.dto";
import {
    AdsPublisherDto,
    AdsPublisherFilterDto,
    CreateAdsPublisherDto,
    UpdateAdsPublisherDto
} from "../dto/ads_publisher.dto";
import { AdsRecordsScanningProcessDto } from "../dto/ads_records_scanning_process.dto";
import { AdsDomainDto } from "../dto/ads_domain.dto";
import { AdsRecordDto } from "../dto/ads_record.dto";

class AdsPublishersService {
    public url = '/ads-publishers';

    constructor(private readonly client: AxiosInstance) {}

    public async getLatestScan(): Promise<AxiosResponse<AdsRecordsScanningProcessDto>> {
        return await this.client.get(`${this.url}/latest-scan`);
    }

    public async getAdsPublisherRecords(id: string): Promise<AxiosResponse<AdsRecordDto[]>> {
        return await this.client.get(`${this.url}/${id}/records`);
    }

    public async getDomainsWithMissingRecords(id: string): Promise<AxiosResponse<AdsDomainDto[]>> {
        return await this.client.get(`${this.url}/${id}/domains-with-missing-records`);
    }

    public async getAdsPublishers(filter?: AdsPublisherFilterDto): Promise<AxiosResponse<ListWithTotalDto<AdsPublisherDto>>> {
        return await this.client.post(this.url, filter);
    }

    public async createAdsPublisher(record: CreateAdsPublisherDto): Promise<AxiosResponse<AdsPublisherDto>> {
        return await this.client.post(`${this.url}/create`, record);
    }

    public async updateAdsPublisher(record: UpdateAdsPublisherDto): Promise<AxiosResponse<AdsPublisherDto>> {
        return await this.client.put(`${this.url}/${record.id}`, record);
    }

    public async scanPublishers(): Promise<void> {
        await this.client.post(`${this.url}/scan`);
    }

    public async exportAdsPublishers(filter?: AdsPublisherFilterDto): Promise<AxiosResponse<BlobPart>> {
        return await this.client.post(`${this.url}/export`, filter);
    }

    public async deleteAdsPublishers(ids: string[]): Promise<AxiosResponse<void>> {
        return await this.client.delete(`${this.url}`, { data: ids });
    }
}

export default AdsPublishersService;
