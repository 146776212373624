import React, { FunctionComponent } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

type RootThemeProps = {};

export const theme = createTheme({
    palette: {
         primary: {
            main: '#009FE3'
         },
        secondary: {
            main: '#2FAC66',
        },
        page: {
            background: '#E5E5E5',
        },
        input: {
             placeholder: '#B0AEAE'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                fontSize: 18,
                height: 40,
                transition: '0.3s',
                "&:hover": {
                    opacity: 0.4,
                    transition: '0.3s'
                }
            },
            textPrimary: {
                paddingLeft: 20,
                paddingRight: 20
            },
            containedSecondary: {
                color: '#fff',
                "&:hover": {
                    background: 'rgba(47, 172, 102, 0.5)',
                    transition: '0.3s'
                }
            },
            containedPrimary: {
                color: '#fff',
                "&:hover": {
                    background: 'rgba(47, 172, 102, 0.5)',
                    transition: '0.3s'
                }
            }
        },
    },
    shape: {
        borderRadius: 3
    },

    typography: {
        fontFamily: 'Montserrat',

        body1: {
            fontWeight: 600,
            fontSize: 14,
        },
        body2: {
            fontSize: 12,
            placeholder: ''
        },
    },
    borders: {
        input: {
            borderBottom: '1px solid #6E6E6E'
        }
    }
});

export const RootThemeProvider: FunctionComponent<RootThemeProps> = props =>
    (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
