import { AxiosInstance, AxiosResponse } from 'axios';
import { ListWithTotalDto } from "../dto/ListWithTotal.dto";
import {
    AdsRecordDto,
    CreateAdsRecordDto,
    UpdateAdsRecordDto,
    AdsRecordFilterDto,
    ImportAdsRecordsDto,
    AdsTxtCheckingResultDto
} from "../dto/ads_record.dto";

class AdsRecordsService {
    public url = '/ads-records';

    constructor(private readonly client: AxiosInstance) {}

    public async getAdsRecords(filter?: AdsRecordFilterDto): Promise<AxiosResponse<ListWithTotalDto<AdsRecordDto>>> {
        return await this.client.post(this.url, filter);
    }

    public async createAdsRecord(record: CreateAdsRecordDto): Promise<AxiosResponse<AdsRecordDto>> {
        return await this.client.post(`${this.url}/create`, record);
    }

    public async updateAdsRecord(record: UpdateAdsRecordDto): Promise<AxiosResponse<AdsRecordDto>> {
        return await this.client.put(`${this.url}/${record.id}`, record);
    }

    public async deleteAdsRecords(ids: string[]): Promise<AxiosResponse<void>> {
        return await this.client.delete(`${this.url}`, { data: ids });
    }

    public async checkAdsTxt(file: File): Promise<AxiosResponse<AdsTxtCheckingResultDto>> {
        const formData = new FormData();
        formData.append('file', file);

        return await this.client.post(`${this.url}/check/ads-txt`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    public async importAdsRecords({ file, demandPartnerId, publisherIds }: ImportAdsRecordsDto): Promise<AxiosResponse<void>> {
        const formData = new FormData();
        formData.append('file', file);

        if (demandPartnerId) {
            formData.append('demandPartnerId', demandPartnerId);
        }

        if (publisherIds) {
            for (const publisherId of publisherIds) {
                formData.append('publisherIds[]', publisherId);
            }
        }

        return await this.client.post(`${this.url}/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    public async exportAdsRecords(filter?: AdsRecordFilterDto): Promise<AxiosResponse<BlobPart>> {
        return await this.client.post(`${this.url}/export`, filter);
    }
}

export default AdsRecordsService;
