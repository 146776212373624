import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RouteProps } from 'react-router';

import { userStore } from '../../stores';

const AuthRoute: React.FC<RouteProps> = observer((props) => {
  const { token } = userStore;

  if (token) {
    return <Redirect to="/ads/domains" />;
  }

  return <Route {...props} />;
});

export default AuthRoute;
