import { UserStore } from "./UserStore";
import { GAMInstanceStore } from "./GAMInstanceStore";
import { PartnerStore } from "./PartnerStore";
import { DomainStore } from "./DomainStore";
import { PublisherStore } from "./PublisherStore";
import { AdsDemandPartnerStore } from "./AdsDemandPartnerStore";
import { AdsRecordStore } from "./AdsRecordStore";
import { AdsPublisherStore } from "./AdsPublisherStore";
import { AdsDomainStore } from "./AdsDomainStore";

import {
    gamInstancesApi,
    usersApi,
    partnerApi,
    publisherApi,
    domainsApi,
    adsDemandPartnersApi,
    adsRecordsApi,
    adsPublishersApi,
    adsDomainsApi
} from '../data-services';
import configService from "../config/configService";

export const userStore = new UserStore(configService, usersApi);
export const gamInstanceStore = new GAMInstanceStore(gamInstancesApi);
export const partnerStore = new PartnerStore(partnerApi, publisherApi);
export const domainStore = new DomainStore(domainsApi, publisherApi, gamInstancesApi, partnerApi);
export const publisherStore = new PublisherStore(publisherApi, partnerApi, gamInstancesApi);
export const adsDemandPartnerStore = new AdsDemandPartnerStore(adsDemandPartnersApi);
export const adsRecordStore = new AdsRecordStore(adsRecordsApi, adsDemandPartnersApi);
export const adsPublisherStore = new AdsPublisherStore(adsPublishersApi, adsDemandPartnersApi);
export const adsDomainStore = new AdsDomainStore(adsDomainsApi, adsPublishersApi);
