import React from 'react';
import ReactDOM from 'react-dom';
import './index.style';
import reportWebVitals from './reportWebVitals';
import { RootThemeProvider } from './RootThemeProvider';
import { BrowserRouter } from 'react-router-dom';
import Root from './pages/Root/Root';

ReactDOM.render(
  <React.StrictMode>
      <RootThemeProvider>
          <BrowserRouter>
              <Root />
          </BrowserRouter>
      </RootThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(console.info);
