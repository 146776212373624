import { AxiosInstance, AxiosResponse } from 'axios';
import {
  PartnerDto,
  PartnerFilterDto,
  CreatePartnerDto,
  UpdatePartnerDto
} from '../dto/partner.dto';

class PartnerService {
  public url = '/partners';

  constructor(private readonly client: AxiosInstance) {}

  public async getPartners(filter?: PartnerFilterDto): Promise<AxiosResponse<PartnerDto[]>> {
    return await this.client.get(this.url, { params: filter });
  }

  public async createPartner(
      partnerData: CreatePartnerDto,
  ): Promise<AxiosResponse<PartnerDto>> {
    return await this.client.post(`${this.url}`, partnerData);
  }

  public async updatePartner(
      partnerData: UpdatePartnerDto,
  ): Promise<AxiosResponse<PartnerDto>> {
    return await this.client.put(`${this.url}/${partnerData.id}`, partnerData);
  }

  public async deletePartner(id: string): Promise<AxiosResponse<void>> {
    return await this.client.delete(`${this.url}/${id}`);
  }
}

export default PartnerService;
